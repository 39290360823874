import React, { useEffect, useState } from "react";
import Img from "../../../assets/customer/new-order-page.png";
import { FaArrowLeft, FaPlus, FaX } from "react-icons/fa6";
import { FaDollarSign } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  setClientInformation,
  setFuneralDetails,
  setOrderDetails,
  setQuotes,
} from "../../../redux/reducer/newOrderCustomerSlice";
import axios from "axios";

const Step1 = ({
  steps,
  setSteps,
  changesOccur,
  setChangesOccur,
  daysToCompleted,
  emptyFields,
}) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("customerToken");
  const clientInformation = useSelector(
    (state) => state.newOrderCustomerSlice.clientInformation
  );
  const orderDetails = useSelector(
    (state) => state.newOrderCustomerSlice.orderDetails
  );
  const funeralDetails = useSelector(
    (state) => state.newOrderCustomerSlice.funeralDetails
  );

  const handleClientInformation = (e) => {
    const { name, value } = e.target;
    dispatch(setClientInformation({ ...clientInformation, [name]: value }));
    setChangesOccur(true);
  };
  const handleOrderDetails = (e) => {
    const { name, value } = e.target;
    dispatch(setOrderDetails({ ...orderDetails, [name]: value }));
    setChangesOccur(true);
  };

  const handleFuneralDetails = (e) => {
    const { name, value } = e.target;
    dispatch(setFuneralDetails({ ...funeralDetails, [name]: value }));
    setChangesOccur(true);
  };

  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  // useEffect(() => {
  //   const currentDate = new Date();
  //   setMinDate(currentDate.toISOString().split("T")[0]);

  //   const maxDate = new Date();
  //   maxDate.setDate(currentDate.getDate() + daysToCompleted);
  //   setMaxDate(maxDate.toISOString().split("T")[0]);
  // }, [daysToCompleted]);

  useEffect(() => {
    const currentDate = new Date();

    // Set the minimum date as currentDate + daysToComplete + 1 (i.e., after `daysToComplete` days)
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() + daysToCompleted + 1); // Adding 1 day extra to ensure the correct day

    // Set the maximum date if needed (e.g., 30 days after the current date)
    const maxDate = new Date();
    maxDate.setDate(currentDate.getDate() + daysToCompleted + 30); // 30 days after today or adjust accordingly

    setMinDate(startDate.toISOString().split("T")[0]);
    setMaxDate(maxDate.toISOString().split("T")[0]);
  }, [daysToCompleted]);

  const [currentTime, setCurrentTime] = useState("");

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0"); // 24-hour format
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    // Update current time when the component mounts
    setCurrentTime(getCurrentTime());

    // Optional: Keep updating the time every minute to keep it current
    const timer = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 60000); // Update every 60 seconds

    return () => clearInterval(timer); // Clean up interval on component unmount
  }, []);

  return (
    <div className="w-full flex text-[14px]  flex-col gap-[20px]">
      <div className="w-full flex flex-col items-center gap-[20px]">
        <h3 className="font-bold w-full">Client Information*</h3>
        <div className="w-full flex justify-start flex-wrap items-center gap-[20px]">
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="businessName" className="font-semibold">
              Business Name*
            </label>
            <input
              type="text"
              name="businessName"
              id="businessName"
              onChange={handleClientInformation}
              value={clientInformation.businessName}
              autoComplete="false"
              className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
              onKeyPress={(e) => {
                if (!/[a-z\s]/i.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="contactName" className="font-semibold">
              Contact Name*
            </label>
            <input
              type="text"
              name="contactName"
              id="contactName"
              onChange={handleClientInformation}
              value={clientInformation.contactName}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("contactName")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              onKeyPress={(e) => {
                if (!/[a-z\s]/i.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {emptyFields.includes("contactName") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="email" className="font-semibold">
              Email*
            </label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={handleClientInformation}
              value={clientInformation.email}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("email")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
            />
            {emptyFields.includes("email") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>

          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="phone" className="font-semibold">
              Phone Number*
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              onChange={handleClientInformation}
              value={clientInformation.phone}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("phone")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              maxLength="10"
              onKeyPress={(e) => {
                if (!/^\d$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {emptyFields.includes("phone") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>

          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="address" className="font-semibold">
              Address*
            </label>
            <input
              type="text"
              name="address"
              id="address"
              onChange={handleClientInformation}
              value={clientInformation.address}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("address")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              onKeyPress={(e) => {
                if (!/[a-z\s]/i.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {emptyFields.includes("address") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>

          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="city" className="font-semibold">
              City*
            </label>
            <input
              type="text"
              name="city"
              id="city"
              onChange={handleClientInformation}
              value={clientInformation.city}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("city")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              onKeyPress={(e) => {
                if (!/[a-z\s]/i.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {emptyFields.includes("city") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>

          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="state" className="font-semibold">
              State*
            </label>
            <input
              type="text"
              name="state"
              id="state"
              onChange={handleClientInformation}
              value={clientInformation.state}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("state")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              onKeyPress={(e) => {
                if (!/[a-z\s]/i.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {emptyFields.includes("state") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>

          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="postalCode" className="font-semibold">
              Postal Code*
            </label>
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              onChange={handleClientInformation}
              value={clientInformation.postalCode}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("postalCode")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              maxLength="6"
              onKeyPress={(e) => {
                if (!/^\d$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {emptyFields.includes("postalCode") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>
        </div>
        <img loading="lazy" src={Img} alt="img" className="" />
      </div>
      <div className="w-full flex flex-col items-center gap-[20px]">
        <h3 className="font-bold w-full">Order Details*</h3>
        <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="orderCreationDate" className="font-semibold">
              Order Creation Date*
            </label>
            <input
              type="date"
              name="orderCreationDate"
              id="orderCreationDate"
              onChange={handleOrderDetails}
              value={orderDetails.orderCreationDate}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("orderCreationDate")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              disabled
            />
          </div>
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="requestedDate" className="font-semibold">
              Requested Date*
            </label>
            <input
              type="date"
              name="requestedDate"
              id="requestedDate"
              onChange={handleOrderDetails}
              value={orderDetails.requestedDate}
              autoComplete="false"
              min={minDate}
              max={maxDate}
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("requestedDate")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
            />
            {emptyFields.includes("requestedDate") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>

          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="requestedTime" className="font-semibold">
              Requested Time*
            </label>
            <input
              type="time"
              name="requestedTime"
              id="requestedTime"
              onChange={handleOrderDetails}
              value={orderDetails.requestedTime}
              autoComplete="off"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("requestedTime")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              // Set the min attribute to the current time in 24-hour format
              min={currentTime} // This should be in `HH:MM` format in 24-hour time
            />
            {emptyFields.includes("requestedTime") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>

          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="orderNumber" className="font-semibold">
              Order Number*
            </label>
            <input
              type="text"
              name="orderNumber"
              id="orderNumber"
              onChange={handleOrderDetails}
              value={orderDetails.orderNumber}
              autoComplete="false"
              className="w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none"
              disabled
            />
          </div>

          {/* <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="styleOption" className="font-semibold">
              Style Option
            </label>
            <input
              type="text"
              name="styleOption"
              id="styleOption"
              onChange={handleOrderDetails}
              value={orderDetails.styleOption}
              autoComplete="false"
              className={`w-full p-[7px] border-2  rounded-[5px] border-[#22D87C] outline-none `}
            />
          </div> */}
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="videoType" className="font-semibold">
              Video Type
            </label>
            <select
              name="videoType"
              id="videoType"
              onChange={handleOrderDetails}
              value={orderDetails.videoType}
              autoComplete="false"
              className={`w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none`}
            >
              <option value="">Select A Video</option>
              <option value="16x9">16x9</option>
              <option value="4x3">4x3</option>
              {/* <option value="no template">No Template</option> */}
            </select>
          </div>
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="productionType" className="font-semibold">
              Production Type*
            </label>
            <select
              name="productionType"
              id="productionType"
              onChange={handleOrderDetails}
              value={orderDetails.productionType}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("productionType")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
            >
              <option value="">Select A Video</option>
              <option value="6-minutes video with scenery">
                6-minutes video with scenery
              </option>
              <option value="9-minutes video with scenery">
                9-minutes video with scenery
              </option>
              <option value="6-minutes photo-only video">
                6-minutes photo-only video
              </option>
              <option value="9-minutes photo-only video">
                9-minutes photo-only video
              </option>

              {/* <option value="no template">No Template</option> */}
            </select>
            {emptyFields.includes("productionType") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>
        </div>
        <img loading="lazy" src={Img} alt="img" className="" />
      </div>

      <div className="w-full flex flex-col items-center gap-[20px]">
        <div className="w-full flex flex-col gap-[4px]">
          <h3 className="font-bold w-full">Funeral Details*</h3>
          {/* <p>Would You like to be contacted when the order is complete:</p> */}
        </div>
        <div className="w-full text-[14px] flex justify-start flex-wrap items-center gap-[20px]">
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="subjectName" className="font-semibold">
              Subject Name*
            </label>
            <input
              type="text"
              name="subjectName"
              id="subjectName"
              onChange={handleFuneralDetails}
              value={funeralDetails.subjectName}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("subjectName")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
              onKeyPress={(e) => {
                if (!/[a-z\s]/i.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {emptyFields.includes("subjectName") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="dateOfBirth" className="font-semibold">
              Date of Birth*
            </label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              onChange={handleFuneralDetails}
              value={funeralDetails.dateOfBirth}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("dateOfBirth")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
            />
            {emptyFields.includes("dateOfBirth") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="dateOfDeath" className="font-semibold">
              Date of Death*
            </label>
            <input
              type="date"
              name="dateOfDeath"
              id="dateOfDeath"
              onChange={handleFuneralDetails}
              value={funeralDetails.dateOfDeath}
              autoComplete="false"
              className={`w-full p-[7px] border-2 ${
                emptyFields.includes("dateOfDeath")
                  ? "border-red-500"
                  : "border-[#22D87C]"
              } rounded-[5px] outline-none`}
            />
            {emptyFields.includes("dateOfDeath") && (
              <span className="text-red-500">This field is required*</span>
            )}
          </div>

          {/* <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="funeralDate" className="font-semibold">
              Funeral Date
            </label>
            <input
              type="date"
              name="funeralDate"
              id="funeralDate"
              onChange={handleFuneralDetails}
              value={funeralDetails.funeralDate}
              autoComplete="false"
              className={`w-full p-[7px] border-2 border-[#22D87C] rounded-[5px] outline-none`}
            />
          </div>
          <div className="w-full sm:w-[48%] xl:w-[32%] flex flex-col gap-[7px]">
            <label htmlFor="funeralTime" className="font-semibold">
              Funeral Time
            </label>
            <input
              type="time"
              name="funeralTime"
              id="funeralTime"
              onChange={handleFuneralDetails}
              value={funeralDetails.funeralTime}
              autoComplete="false"
              className={`w-full p-[7px] border-2 border-[#22D87C]
            rounded-[5px] outline-none`}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Step1;
