import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FaBars, FaX } from "react-icons/fa6";
import TableSkeleton from "../layout/tableSkeleton";
import { useDispatch, useSelector } from "react-redux";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import {
  setCoverImgPricing,
  setMusicPricing,
  setSceneryPricing,
  setTotalPricing,
  setUploadMusicPricing,
  setUploadPricing,
  setUploadVideoPricing,
} from "../../../redux/reducer/pricingSlice";

const OrderList = () => {
  const customerId = localStorage.getItem("customerId");
  const token = localStorage.getItem("customerToken");
  const [data, setData] = useState([]);
  const [active, setActiveTab] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showOptions, setShowOptions] = useState(null);
  const [modal, setModal] = useState(false);
  const handleModal = (index) => {
    setModal(index === modal ? null : index);
  };
  const navigate = useNavigate();
  const getOrders = async () => {
    setShowFilter(false);

    setData(false);
    axios({
      method: "POST",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/get-orders-by-customerid/${customerId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: active,
        startDate: startDate,
        endDate: endDate,
        search: search,
      },
    })
      .then((res) => {
        setShowFilter(false);

        let data = res?.data?.Data;
        console.log(data);

        setData(data || []);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getOrders();
  }, [active]);

  const handleShowOptions = (index) => {
    setShowOptions(index === showOptions ? null : index);
  };

  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };
  let formattedDate;
  const totalPricing = useSelector((state) => state.pricingSlice.totalPricing);
  const musicPricing = useSelector((state) => state.pricingSlice.musicPricing);
  const uploadPricing = useSelector(
    (state) => state.pricingSlice.uploadPricing
  );
  const coverImgPricing = useSelector(
    (state) => state.pricingSlice.coverImgPricing
  );
  const sceneryPricing = useSelector(
    (state) => state.pricingSlice.sceneryPricing
  );
  const uploadVideoPricing = useSelector(
    (state) => state.pricingSlice.uploadVideoPricing
  );
  const handlePayment = (id) => {
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/payment/order-checkout-session",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        orderId: id,
        totalPrice: totalPricing,
        musicPrice: musicPricing,
        imagePrice: uploadPricing,
        videoPrice: uploadVideoPricing,
        coverImagePrice: coverImgPricing,
        sceneryPrice: sceneryPricing,
        cost: 10,
      },
    })
      .then((res) => {
        showToastSuccess(res?.data?.message);
        window.location.href = res?.data?.Data?.url;
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  const [deleteOrderId, setDeleteOrderId] = useState(null);
  const handleDeleteOrder = async () => {
    await axios({
      method: "DELETE",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/delete-order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: deleteOrderId,
      },
    })
      .then((res) => {
        console.log(res);
        showToastSuccess(res?.data?.message);

        // Remove the deleted order from the current data
        setData((prevData) =>
          prevData.filter((order) => order.id !== deleteOrderId)
        );

        setModal(false);
      })
      .catch((err) => {
        console.log(err);
        setModal(false);
        showToastError(err?.response?.data?.message);
      });
  };
  const dispatch = useDispatch();
  const handleGetOrderById = async (orderId) => {
    await axios({
      method: "GET",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-order/${orderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res?.data?.Data;
        dispatch(setTotalPricing(data?.pricingObj?.totalPrice || 0));
        dispatch(setMusicPricing(data?.pricingObj?.musicPrice || 0));
        dispatch(setUploadPricing(data?.pricingObj?.imagePrice || 0));
        dispatch(
          setUploadMusicPricing(data?.pricingObj?.uploadMusicPricing || 0)
        );
        dispatch(setCoverImgPricing(data?.pricingObj?.coverImagePrice || 0));
        dispatch(setSceneryPricing(data?.pricingObj?.sceneryPrice || 0));
        dispatch(setUploadVideoPricing(data?.pricingObj?.videoPrice || 0));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      {data ? (
        <Layout>
          <div className="w-full flex h-screen flex-col p-[30px] sm:p-[50px]">
            <h2 className="text-black font-bold text-[25px]">All Orders</h2>
            <div className="flex justify-between">
              <div className="flex justify-start gap-3">
                <button
                  className={`${
                    active === ""
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("");
                  }}
                >
                  All
                </button>
                <button
                  className={`${
                    active === "Draft"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("Draft");
                  }}
                >
                  Draft
                </button>
                <button
                  className={`${
                    active === "Published"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("Published");
                  }}
                >
                  Published
                </button>
                <button
                  className={`${
                    active === "Editing"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("Editing");
                  }}
                >
                  Editing
                </button>
                <button
                  className={`${
                    active === "Completed"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("Completed");
                  }}
                >
                  Completed
                </button>
              </div>
              <button
                className="bg-green-500 px-10 py-3 text-white font-bold rounded-[15px]"
                onClick={toggleFilter}
              >
                Filter
              </button>
            </div>
            {showFilter && (
              <div className="bg-white absolute z-[100] border-2 right-10 top-[22%] p-4 text-[25px] font-bold shadow-md rounded-sm">
                <h3>Filters</h3>
                <p className="text-[20px] font-thin">Date Between</p>
                <div className="flex gap-2 items-center">
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    id="date"
                    name="date"
                    className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  to
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    id="date"
                    name="date"
                    className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <p className="text-[20px] font-thin mt-[10px]">Search</p>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                  className="bg-green-500 px-7 py-2 text-white font-bold rounded-[15px] text-[15px] mt-2"
                  onClick={() => {
                    getOrders();
                    // This triggers the useEffect to re-fetch data
                    setEndDate("");
                    setSearch("");
                    setStartDate("");
                  }}
                >
                  Search
                </button>
              </div>
            )}

            <div className="w-full my-[30px] overflow-x-auto hide-scrollbar bg-white h-[600px]">
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="border text-[12px] border-gray-200 rounded-t-[20px] bg-[#fcfdfd]">
                    <th className="sm:p-[12px] px-[30px] text-center py-[12px] sm:text-left">
                      CONTACT NAME
                    </th>
                    {/* <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      FUNERAL DATE / TIME
                    </th> */}
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      CITY / STATE
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      CREATED AT
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      TYPE
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      PAID
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      REQUESTED DATE
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      ACTIONS
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full border relative border-gray-200">
                  {data.map(
                    (item, index) => (
                      (formattedDate =
                        moment(item.funeralDate).format("YYYY-MM-DD") ||
                        "Not provided"),
                      (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 1
                              ? "bg-[#fcfdfd] text-[12px]"
                              : "bg-white text-[12px]"
                          } relative`}
                        >
                          <td className="p-[12px] text-left">
                            {" "}
                            {item.contactName || "Not provided"}
                          </td>
                          {/* <td className="p-[12px] text-left">
                            {item.funeralDate === null ? "" : formattedDate} /{" "}
                            {item.funeralTime || "Not provided"}
                          </td> */}
                          <td className="p-[12px] text-left">
                            {" "}
                            {item.city || "Not provided"} /{" "}
                            {item.state || "Not provided"}
                          </td>
                          <td className="p-[12px] text-left">
                            {moment(item.createdAt).format("YYYY-MM-DD")}
                          </td>
                          <td className="p-[12px] text-left">
                            <div className="flex items-center">
                              <p
                                className={`p-[7px] rounded-[7px] font-normal ${
                                  item.type === "Draft"
                                    ? "bg-yellow-500  border-[2px] border-yellow-500  text-yellow-500 bg-opacity-[.3]"
                                    : item.type === "Completed"
                                    ? "bg-blue-500  border-[2px] border-blue-500 text-blue-500 bg-opacity-[.3]"
                                    : item.type === "Published"
                                    ? "bg-green-500  border-[2px] border-green-500 text-green-500 bg-opacity-[.3]"
                                    : item.type === "Editing"
                                    ? "bg-red-500  border-[2px] border-red-500 text-red-500 bg-opacity-[.3]"
                                    : ""
                                }`}
                              >
                                {item.type}
                              </p>
                            </div>
                          </td>
                          <td className="p-[12px] text-left">
                            {item?.paid === true
                              ? "Paid"
                              : item?.paid === false
                              ? "Not Paid"
                              : null}
                          </td>
                          <td className="p-[12px] text-left">
                            {item?.requestedDate || "Not Provided"}
                          </td>
                          <td className="">
                            <div className="flex items-center justify-center">
                              {showOptions === index ? (
                                <FaX
                                  onClick={() => handleShowOptions(index)}
                                  className="cursor-pointer"
                                />
                              ) : (
                                <FaBars
                                  onClick={() => {
                                    handleShowOptions(index);
                                    handleGetOrderById(item?.id);
                                  }}
                                  className="cursor-pointer"
                                />
                              )}
                            </div>
                            {showOptions === index && (
                              <div className="flex w-[20%] bg-[#f5f5f5] z-[5] rounded-[12px] shadow-lg flex-col absolute right-[0px] top-[50px] items-center gap-[7px]">
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/customer/order-detail/${item.id}`
                                    )
                                  }
                                  className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                >
                                  Preview
                                </button>
                                {item.type !== "Draft" ||
                                item?.status === "production" ? null : (
                                  <button
                                    onClick={() =>
                                      navigate(`/customer/new-order/${item.id}`)
                                    }
                                    className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                  >
                                    Edit
                                  </button>
                                )}
                                <button
                                  onClick={() =>
                                    navigate(`/customer/delivery/${item.id}`)
                                  }
                                  className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                >
                                  View Status
                                </button>
                                {/* {item?.type !== "Draft" &&
                                item?.paid !== true ? (
                                  <button
                                    onClick={() => {
                                      if (totalPricing > 0) {
                                        handlePayment(item?.id);
                                      }
                                    }}
                                    className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                  >
                                    Pay Now
                                  </button>
                                ) : null} */}
                                {item?.type === "Draft" ? (
                                  <button
                                    onClick={() => {
                                      setDeleteOrderId(item?.id);
                                      handleModal("deleteOrder");
                                      setShowOptions(false);
                                    }}
                                    className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                  >
                                    Delete
                                  </button>
                                ) : null}
                              </div>
                            )}
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {modal === "deleteOrder" ? (
            <div className="w-full absolute top-0 z-[99] left-0 bg-black bg-opacity-[.5] h-full p-[20px] sm:p-[30px] flex flex-col justify-center items-center">
              <div className="w-full sm:w-[80%] xl:w-[50%] h-[300px] p-[20px] sm:p-[25px] rounded-[12px] bg-white flex flex-col gap-[15px] justify-center items-center">
                <h3 className="text-[20px] text-center font-semibold ">
                  Are you sure, You want to delete this order?
                </h3>
                <div className="w-full flex gap-[12px] justify-center items-center">
                  <button
                    onClick={() => {
                      setModal(false);
                    }}
                    className="px-[20px] py-[10px] rounded-[7px] font-semibold bg-[#e8e8e8]"
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      handleDeleteOrder();
                    }}
                    className="px-[20px] py-[10px] text-white rounded-[7px] font-semibold bg-red-500 "
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </Layout>
      ) : (
        <TableSkeleton />
      )}
    </div>
  );
};

export default OrderList;
