import React, { useState } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(!showModal);
  };
  const clientInformation = useSelector(
    (state) => state.newOrderAdminSlice.clientInformation
  );
  const orderDetails = useSelector(
    (state) => state.newOrderAdminSlice.orderDetails
  );
  const funeralDetails = useSelector(
    (state) => state.newOrderAdminSlice.funeralDetails
  );
  const selectedVideo = useSelector(
    (state) => state.newOrderAdminSlice.selectedVideo
  );
  const selectedImg = useSelector(
    (state) => state.newOrderAdminSlice.selectedImg
  );
  const selectedMusic = useSelector(
    (state) => state.newOrderAdminSlice.selectedMusic
  );
  const customerInfo = useSelector((state) => state.customerInfoSlice.customer);
  const navigate = useNavigate();
  const token = localStorage.getItem("adminToken");
  console.log(customerInfo);

  const reqBody = {
    businessName: clientInformation?.bussinessName,
    contactName:
      clientInformation?.firstName + " " + clientInformation?.lastName,
    email: clientInformation?.email,
    phone: clientInformation?.phone,
    address: clientInformation?.address,
    city: clientInformation?.city,
    state: clientInformation?.state,
    postalCode: Number(clientInformation?.postalCode),
    requestedDate: orderDetails?.requestedDate || null,
    styleOption: orderDetails?.styleOption,
    videoType: orderDetails?.videoType,
    productionType: orderDetails?.productionType,
    subjectName: funeralDetails?.subjectName,
    orderCreationDate: orderDetails?.orderCreationDate || null,
    dateOfBirth: funeralDetails?.dateOfBirth || null,
    dateOfDeath: funeralDetails?.dateOfDeath || null,
    funeralDate: funeralDetails?.funeralDate || null,
    funeralTime: funeralDetails?.funeralTime || null,
    coverPhotos: selectedImg,
    scenery: selectedVideo,
    music: selectedMusic,
    customer_id: Number(localStorage.getItem("adminId")),
    pricingObj: {
      totalPricing: 0,
      musicPricing: 0,
      uploadPricing: 0,
      coverImgPricing: 0,
      sceneryPricing: 0,
      uploadVideoPricing: 0,
    },
  };

  const handleSubmit = () => {
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/create-order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: reqBody,
    })
      .then((res) => {
        navigate(`/admin/new-order/${res?.data?.Data?.id}`);
        setShowModal(false);
      })
      .catch((err) => {});
  };
  return (
    <div className="w-full flex  relative max-h-[100vh] overflow-y-hidden">
      <ToastContainer />
      <div className="w-[17%] hidden xl:block border-r border-gray-200">
        <Sidebar
          showModal={showModal}
          setShowModal={setShowModal}
          handleShowModal={handleShowModal}
        />
      </div>
      <div className="w-full xl:w-[83%]   px-[10px] h-screen overflow-y-scroll hide-scrollbar  flex flex-col  ">
        <Header
          showModal={showModal}
          setShowModal={setShowModal}
          handleShowModal={handleShowModal}
        />

        <div className="bg-[#b2b7bb] py-[20px] px-[15px] w-full h-full rounded-[20px]">
          <div className="w-full  h-full overflow-y-scroll bg-white rounded-[20px] hide-scrollbar">
            {children}
          </div>
        </div>
      </div>
      {showModal === true ? (
        <div className="w-full h-full p-[30px] fixed bg-black top-0 left-0 z-[99] bg-opacity-[0.5] flex flex-col justify-center items-center">
          <div className="w-full text-center sm:w-[80%] xl:w-[50%] h-[300px] justify-center p-[30px] flex bg-white rounded-[7px] flex-col items-center gap-[20px]">
            <h2 className="font-semibold text-[24px]">
              Do you want to create new order?
            </h2>
            <div className="w-full flex gap-[12px] justify-center items-center">
              <button
                onClick={() => setShowModal(false)}
                className="px-[20px] font-semibold py-[12px] bg-red-500 text-white  rounded-[7px]"
              >
                No
              </button>
              <button
                onClick={() => handleSubmit()}
                className="px-[20px] font-semibold py-[12px] bg-green-500 text-white  rounded-[7px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Layout;
