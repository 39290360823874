import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { FaBars, FaCheck, FaTrash, FaX } from "react-icons/fa6";
import { showToastError, showToastSuccess } from "../../../utils/toastify";
import TableSkeleton from "../layout/tableSkeleton";
import { useDispatch } from "react-redux";
import {
  setCurrentPath,
  setFolderUrl,
} from "../../../redux/reducer/folderSystemSlice";
import Spinner from "../../../utils/spinner";
import { setAdminSidebar } from "../../../redux/reducer/sidebarSlice";

const AdminOrderList = () => {
  useEffect(() => {
    dispatch(setAdminSidebar("order-list"));
  });
  const adminId = localStorage.getItem("adminId");
  const token = localStorage.getItem("adminToken");
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [modal, setModal] = useState(false);
  const [active, setActiveTab] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [typeIndex, setTypeIndex] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [orderCreatedDate, setOrderCreatedDate] = useState(null);
  const [imgData, setImgData] = useState([]);
  const handleImgDataChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const allowedFiles = newFiles.filter(
      (file) => file.type === "image/png" || file.type === "image/jpeg"
    );
    const combinedFiles = [
      ...imgData,
      ...allowedFiles.map((file) => ({
        url: URL.createObjectURL(file),
        file: file,
      })),
    ];
    setImgData(combinedFiles);
    handleUploadSubmit(combinedFiles);
  };
  const deleteImg = (id) => {
    const filterData = imgData?.filter((_, item) => item !== id);
    setImgData(filterData);
  };
  const [completeOrderNote, setCompleteOrderNote] = useState("");
  const handleCompleteOrderNote = (e) => {
    setCompleteOrderNote(e.target.value);
  };
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    loadMoreItems(false);
  }, []);

  useEffect(() => {
    console.log(offset, "offset");
  }, [offset]);
  useEffect(() => {
    if (inView && hasMore && !loading) {
      loadMoreItems(true);
    }
  }, [inView, hasMore, loading]);

  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  const fetchData = async () => {
    console.log(offset);
    setLoading(true);
    axios({
      method: "POST",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-all-orders/?offset=${offset}&limit=14`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: active,
        startDate: startDate,
        endDate: endDate,
        search: search,
      },
    })
      .then((res) => {
        const data = res?.data?.Data || [];
        setData(
          (prevItems) => (offset === 0 ? data : [...prevItems, ...data]) || data
        );
        if (data.length < 14) {
          setHasMore(false);
        } else {
          console.log(offset + 14, "offset op");
          setOffset(offset + 14);
        }
        setLoading(false);
        if (initialLoad) setInitialLoad(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const loadMoreItems = (applyDelay) => {
    if (applyDelay && !initialLoad) {
      setTimeout(fetchData, 2000); // 2 seconds delay
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    setData([]);
    setOffset(0);
    setHasMore(true);
    fetchData();
  }, [active]);

  const [showOptions, setShowOptions] = useState(false);
  const handleShowOptions = (index) => {
    setShowOptions(index === showOptions ? null : index);
  };

  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [orderIdType, setOrderIdType] = useState("");
  const handleTypeChange = (e) => {
    const selectedValue = e?.target?.value;
    setType(selectedValue);

    if (selectedValue?.toLowerCase() === "completed") {
      setModal("completeOrder");
    } else {
      setModal("typeChange");
    }
  };

  const dispatch = useDispatch();

  const handleUpdateType = () => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[typeIndex] = {
        ...newData[typeIndex],
        type: type,
      };
      console.log(newData, "spread operator");

      return newData;
    });
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/change-type",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: orderIdType,
        type: type,
      },
    })
      .then((res) => {
        console.log(res?.data?.data?.type, "lavi");
        setType(res?.data?.data?.type);
        showToastSuccess(res?.data?.message);
        setOrderIdType("");
        setModal(false);
        // fetchData();
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  const fetchFilterData = () => {
    setData(false);
    setLoading(true);
    setShowFilter(false);

    setOffset(0);
    axios({
      method: "POST",
      url: `https://funeral-api.hitoritech.co.uk/api/v1/admin/get-all-orders/?offset=0&limit=14`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: active,
        startDate: startDate,
        endDate: endDate,
        search: search,
      },
    })
      .then((res) => {
        const data = res?.data?.Data || [];
        setData(data);
        setHasMore(data.length === 14);
        setOffset(data.length);
        setLoading(false);
        setShowFilter(false);
      })
      .catch((err) => {
        setLoading(false);
        setShowFilter(false);
      });
  };

  const handleViewFiles = (item) => {
    const folderPath = `${moment(item?.createdAt).year()}/${moment(
      item?.createdAt
    ).format("MMMM")}/${item?.id}`;
    // const length = folderPath.split("/").length - 2;
    // const splitValue = folderPath.split("/")[length];
    const splitValue = folderPath.split("/");
    dispatch(setFolderUrl(folderPath));
    dispatch(setCurrentPath(splitValue));
    navigate("/admin/file-explorer");
  };

  const handleModal = (index) => {
    setModal(index === modal ? null : index);
  };
  const [searchBox, setSearchBox] = useState("");
  const handleSearchBoxChange = (e) => {
    setSearchBox(e.target.value);
  };
  const [editors, setEditors] = useState([]);
  const handleSearchBoxSubmit = () => {
    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/search-editor",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: orderIdType,
        search: searchBox,
      },
    })
      .then((res) => {
        setEditors(res?.data);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  const handleAllotEditor = (item) => {
    setOffset(0);
    const reqBody = {
      id: orderIdType,
      editorArry: [item],
    };
    axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/allot-editor",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: reqBody,
    })
      .then((res) => {
        fetchData();
        handleSearchBoxSubmit();
        setModal(false);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };

  const [filesUploaded, setFilesUploaded] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleUploadSubmit = (img) => {
    setIsFileUploading(true);
    const formData = new FormData();

    img.forEach((file) => {
      formData.append("files", file.file);
    });
    formData.append("folderPath", "2024/admin/data");

    axios({
      method: "POST",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/spaces/upload",

      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((res) => {
        const formattedArray = res?.data?.urls?.map((item) => {
          const cleanUrl = item.split("  /");
          const constructUrl = cleanUrl?.join("/");
          return constructUrl;
        });

        console.log(formattedArray, "formattedArray");
        // handleUpdateImages(formattedArray);
        setFilesUploaded(formattedArray);
        showToastSuccess(res?.data?.message);
        setIsFileUploading(false);
      })
      .catch((err) => {
        setIsFileUploading(false);
        showToastError(err?.response?.data?.message);
      });
  };

  const handlePutReq = async () => {
    await axios({
      method: "PUT",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/change-type",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: "Completed",
        id: orderIdType,
        proof: {
          path: filesUploaded,
          text: completeOrderNote,
        },
      },
    })
      .then((res) => {
        // fetchData();

        showToastSuccess(res?.data?.message);
        let orderId = res?.data?.data?.id;

        navigate(`/admin/delivery/${orderId}`);
      })
      .catch((err) => {
        showToastError(err?.response?.data?.message);
      });
  };
  const [deleteOrderId, setDeleteOrderId] = useState(null);
  const handleDeleteOrder = async () => {
    await axios({
      method: "DELETE",
      url: "https://funeral-api.hitoritech.co.uk/api/v1/admin/delete-order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: deleteOrderId,
      },
    })
      .then((res) => {
        console.log(res);
        showToastSuccess(res?.data?.message);

        // Remove the deleted order from the current data
        setData((prevData) =>
          prevData.filter((order) => order.id !== deleteOrderId)
        );

        setModal(false);
      })
      .catch((err) => {
        console.log(err);
        setModal(false);
        showToastError(err?.response?.data?.message);
      });
  };

  return (
    <div>
      {data ? (
        <Layout>
          <div className="w-full flex h-screen flex-col gap-[15px] p-[30px] xl:p-[50px]">
            <h2 className="text-black font-bold text-[25px]">All Orders</h2>
            <div className="flex justify-between">
              <div className="flex justify-start gap-3">
                <button
                  className={`${
                    active === ""
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("");
                    setOffset(0);
                  }}
                >
                  All
                </button>
                <button
                  className={`${
                    active === "Draft"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("Draft");
                    setOffset(0);
                  }}
                >
                  Draft
                </button>
                <button
                  className={`${
                    active === "Published"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("Published");
                    setOffset(0);
                  }}
                >
                  Published
                </button>
                <button
                  className={`${
                    active === "Editing"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("Editing");
                    setOffset(0);
                  }}
                >
                  Editing
                </button>
                <button
                  className={`${
                    active === "Completed"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500`}
                  onClick={() => {
                    setActiveTab("Completed");
                    setOffset(0);
                  }}
                >
                  Completed
                </button>
                <button
                  className={`${
                    active === "paid"
                      ? "text-green-500 border-b-2 border-green-500"
                      : ""
                  } text-[20px] text-gray-500 capitalize`}
                  onClick={() => {
                    setActiveTab("paid");
                    setOffset(0);
                  }}
                >
                  paid
                </button>
              </div>
              <button
                className="bg-green-500 px-10 py-3 text-white font-bold rounded-[15px]"
                onClick={() => {
                  toggleFilter();
                }}
              >
                Filter
              </button>
            </div>
            {showFilter && (
              <div className="bg-white absolute z-[100] border-2 right-10 top-[22%] p-4 text-[25px] font-bold shadow-md rounded-sm">
                <h3>Filters</h3>
                <p className="text-[20px] font-thin">Date Between</p>
                <div className="flex gap-2 items-center">
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    id="date"
                    name="date"
                    className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  to
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    id="date"
                    name="date"
                    className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <p className="text-[20px] font-thin mt-[10px]">Search</p>
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                  className="bg-green-500 px-7 py-2 text-white font-bold rounded-[15px] text-[15px] mt-2"
                  onClick={() => {
                    fetchFilterData();
                    setEndDate("");
                    setSearch("");
                    setStartDate("");
                  }}
                >
                  Search
                </button>
              </div>
            )}
            <div className="w-full overflow-x-auto hide-scrollbar bg-white h-[600px]">
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="border text-[12px] border-gray-200 rounded-t-[20px] bg-[#fcfdfd]">
                    <th className="sm:p-[12px] px-[30px] text-center py-[12px] sm:text-left">
                      ORDER ID
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      NAME
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      ADDRESS
                    </th>
                    {/* <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      FUNERAL DATE / TIME
                    </th> */}
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      STAGE
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      PAYMENT
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      ALLOTED EDITOR
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      REQUESTED DATE
                    </th>
                    <th className="sm:p-[12px] px-[50px] text-center py-[12px] sm:text-left">
                      ACTIONS
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full border relative border-gray-200">
                  {data.map(
                    (item, index) => (
                      console.log(item.type),
                      (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 1
                              ? "bg-[#fcfdfd] text-[12px]"
                              : "bg-white text-[12px]"
                          } relative`}
                        >
                          <td className="p-[12px] text-left">{item?.id}</td>
                          <td className="p-[12px] text-left">
                            {item?.contactName}
                          </td>
                          <td className="p-[12px] text-left">
                            {item?.address}
                          </td>
                          {/* <td className="p-[12px] text-left">
                            {item?.funeralDate || item?.funeralTime ? (
                              <div>
                                {item?.funeralDate !== null
                                  ? moment(item?.funeralDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  : ""}{" "}
                                / {item?.funeralTime}
                              </div>
                            ) : null}
                          </td> */}
                          <td className="p-[12px] text-left">
                            <select
                              name="type"
                              id="type"
                              onChange={(e) => handleTypeChange(e)}
                              onClick={(e) => {
                                setOrderIdType(item?.id);
                                setTypeIndex(index);
                              }}
                              value={item?.type}
                              className="outline-none cursor-pointer"
                            >
                              <option value="">Select Stage</option>
                              <option value="Draft">Draft</option>
                              <option value="Published">Published</option>
                              <option value="Editing">Editing</option>
                              {/* {item?.type === "Published" ? ( */}
                              <option value="Completed">Completed</option>
                              <option value="Re-edit-1">Re edit 1</option>
                              <option value="Re-edit-2">Re edit 2</option>
                              {/* ) : null} */}
                            </select>
                          </td>
                          <td className="p-[12px] text-center">
                            {item?.paid === true
                              ? "Paid"
                              : item?.paid === false
                              ? "Not Paid"
                              : null}
                          </td>
                          <td className="p-[12px] text-left">
                            {item?.editor?.length === 0
                              ? "Not Alloted"
                              : item?.editor[0]?.name}
                          </td>
                          <td className="p-[12px] text-left">
                            {item?.requestedDate || "Not Provided"}
                          </td>

                          <td className="">
                            <div className="flex items-center justify-center">
                              {showOptions === index ? (
                                <FaX
                                  onClick={() => handleShowOptions(index)}
                                  className="cursor-pointer"
                                />
                              ) : (
                                <FaBars
                                  onClick={() => handleShowOptions(index)}
                                  className="cursor-pointer"
                                />
                              )}
                            </div>
                            {showOptions === index ? (
                              <div className="flex w-[20%] bg-[#f5f5f5] z-[2] rounded-[12px] shadow-lg flex-col absolute right-[0px] top-[50px] items-center gap-[7px]">
                                <button
                                  onClick={() =>
                                    navigate(`/admin/order-detail/${item?.id}`)
                                  }
                                  className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-[12px] font-medium leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                >
                                  Preview
                                </button>
                                <button
                                  onClick={() =>
                                    navigate(`/admin/new-order/${item?.id}`)
                                  }
                                  className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-[12px] font-medium leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() =>
                                    navigate(`/admin/delivery/${item?.id}`)
                                  }
                                  className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-[12px] font-medium leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                >
                                  View Status
                                </button>
                                <button
                                  onClick={() => {
                                    handleViewFiles(item);
                                  }}
                                  className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-[12px] font-medium leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                >
                                  View Files
                                </button>

                                <button
                                  onClick={() => {
                                    handleModal("allot-order");
                                    setShowOptions(false);
                                    setOrderIdType(item?.id);
                                  }}
                                  className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-[12px] font-medium leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                >
                                  Allot Order
                                </button>

                                <button
                                  onClick={() => {
                                    setDeleteOrderId(item?.id);
                                    handleModal("deleteOrder");
                                    setShowOptions(false);
                                  }}
                                  className="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-[12px] font-medium leading-none text-gray-600 py-3 px-5  focus:outline-none w-full border-b border-gray-200 "
                                >
                                  Delete
                                </button>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      )
                    )
                  )}
                  {hasMore && !loading && (
                    <div
                      ref={ref}
                      className="absolute left-0 bottom-[-100px] text-center py-4 w-full flex justify-center"
                    >
                      <div className="w-[50px] h-[50px] rounded-[50%] border-t-2 border-black animate-spin"></div>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {modal === "typeChange" ? (
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-[.5] flex flex-col justify-center items-center p-[20px] z-[10]">
              <div className="w-full sm:w-[80%] xl:w-[50%] bg-white p-[50px] rounded-[10px] flex flex-col gap-[20px] items-center">
                <h2 className="text-[22px] xl:text-[30px] font-bold text-center">
                  Do you want to change the Stage of the order ?
                </h2>
                <div className="w-full flex items-center justify-center gap-[12px]">
                  <button
                    onClick={() => {
                      setModal(false);
                      setShowOptions(false);
                    }}
                    className="px-[20px] rounded-[7px] bg-red-500 text-white py-[12px]"
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      handleUpdateType();
                    }}
                    className="px-[20px] py-[12px] rounded-[7px] bg-green-500 text-white"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {modal === "allot-order" ? (
            <div className="absolute z-[10] top-0 left-0 w-full h-full bg-black bg-opacity-[.5] flex flex-col justify-center items-center p-[20px]">
              <div className="w-full sm:w-[80%]  bg-white p-[50px] rounded-[10px] flex flex-col gap-[20px] items-center">
                <div className="w-full flex justify-end">
                  <FaX
                    className="cursor-pointer"
                    onClick={() => {
                      setModal(false);
                      setShowOptions(false);
                    }}
                  />
                </div>
                <div className="w-full xl:w-[80%] flex items-center gap-[12px]">
                  <input
                    type="text"
                    name="searchBox"
                    id="searchBox"
                    onChange={handleSearchBoxChange}
                    value={searchBox}
                    autoComplete="false"
                    className="p-[7px] w-full bg-transparent border border-gray-200 rounded-[7px]"
                    placeholder="Search here...."
                  />
                  <button
                    onClick={handleSearchBoxSubmit}
                    className="px-[20px] py-[7px] rounded-[7px] bg-gray-200 text-gray-400 border border-gray-400"
                  >
                    Search
                  </button>
                </div>
                <div className="w-full overflow-x-auto hide-scrollbar  bg-white   h-[400px]   ">
                  <table className="w-full">
                    <thead>
                      <tr className="border text-[12px] border-gray-200 rounded-t-[20px] bg-[#fcfdfd]">
                        <th className="sm:p-[12px] px-[30px] text-center py-[12px]  sm:text-left">
                          NAME
                        </th>
                        <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                          EMAIL
                        </th>
                        <th className="sm:p-[12px] px-[50px] text-center py-[12px]  sm:text-left">
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full border border-gray-200  ">
                      {editors?.Data?.map((item, index) => (
                        <tr
                          key={index}
                          className={`
                  ${
                    index % 2 === 1
                      ? "bg-[#fcfdfd]  text-[12px]"
                      : "bg-white text-[12px]"
                  } relative
                   `}
                        >
                          <td className="p-[12px] text-left">{item?.name}</td>

                          <td className="p-[12px] text-left">{item?.email}</td>
                          <td className="">
                            <div className="w-full flex justify-center items-center">
                              {editors?.alloted[0]?.id === item?.id ? (
                                <button className="flex items-center gap-[7px] px-[20px] bg-green-400  py-[10px] rounded-[7px]">
                                  Alloted
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleAllotEditor(item);
                                  }}
                                  className="flex items-center gap-[7px] px-[20px] bg-gray-200  py-[10px] rounded-[7px]"
                                >
                                  <FaCheck /> Allot
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
          {modal === "completeOrder" ? (
            <div className="w-full absolute top-0 left-0 bg-black bg-opacity-[.5] h-full p-[20px] sm:p-[30px] flex flex-col justify-center items-center">
              <div className="w-full sm:w-[80%] xl:w-[50%] p-[20px] sm:p-[25px] rounded-[12px] bg-white flex flex-col gap-[15px] justify-center items-center">
                <div className="w-full flex justify-end">
                  <FaX
                    onClick={() => setModal(false)}
                    className="cursor-pointer"
                  />
                </div>
                <div className="w-full relative p-[20px] border-2 border-dashed border-black flex justify-center items-center">
                  <label
                    htmlFor="imgData"
                    className="w-full flex justify-center"
                  >
                    Upload Image
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    multiple
                    name="imgData"
                    id="imgData"
                    onChange={handleImgDataChange}
                    className="w-full h-full opacity-0 absolute top-0 left-0 cursor-pointer"
                  />
                </div>
                {imgData?.length !== 0 ? (
                  <div className="w-full flex flex-wrap gap-[15px] items-center justify-center">
                    {imgData?.map((item, index) => {
                      return (
                        <div key={index} className="relative w-[25%] h-[150px]">
                          <img
                            loading="lazy"
                            src={item?.url}
                            alt="img"
                            className="w-full h-full object-contain"
                          />
                          <FaTrash
                            className="absolute top-[5px] cursor-pointer right-[5px]"
                            onClick={() => deleteImg(index)}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <div className="w-full flex flex-col gap-[10px]">
                  <label htmlFor="completeOrderNote">Note*</label>
                  <input
                    type="text"
                    name="completeOrderNote"
                    id="completeOrderNote"
                    value={completeOrderNote}
                    onChange={handleCompleteOrderNote}
                    className="p-[10px] rounded-[7px] border-2 border-green-500 outline-none"
                  />
                </div>
                <div className="w-full flex justify-center">
                  <button
                    onClick={handlePutReq}
                    className="px-[20px] py-[12px] rounded-[10px] bg-green-500 text-white"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {modal === "deleteOrder" ? (
            <div className="w-full absolute top-0 z-[99] left-0 bg-black bg-opacity-[.5] h-full p-[20px] sm:p-[30px] flex flex-col justify-center items-center">
              <div className="w-full sm:w-[80%] xl:w-[50%] h-[300px] p-[20px] sm:p-[25px] rounded-[12px] bg-white flex flex-col gap-[15px] justify-center items-center">
                <h3 className="text-[20px] text-center font-semibold ">
                  Are you sure, You want to delete this order?
                </h3>
                <div className="w-full flex gap-[12px] justify-center items-center">
                  <button
                    onClick={() => {
                      setModal(false);
                    }}
                    className="px-[20px] py-[10px] rounded-[7px] font-semibold bg-[#e8e8e8]"
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      handleDeleteOrder();
                    }}
                    className="px-[20px] py-[10px] text-white rounded-[7px] font-semibold bg-red-500 "
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </Layout>
      ) : (
        <TableSkeleton />
      )}

      {isFileUploading === true ? <Spinner /> : null}
    </div>
  );
};

export default AdminOrderList;
